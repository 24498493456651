<template>
  <div style="height: inherit">
    <div v-if="$acl.canOneOf(['medialibrary.*'])" style="height: inherit">
      <div class="body-content-overlay" />
      <portal to="nav-bar-left-column">
        <page-title title="Media Library" subtitle="All your assets" />
      </portal>
      <library-area :current-folder="currentFolder" />

      <portal to="content-renderer-sidebar-left">
        <library-sidebar @folder-selected="onFolderSelected" />
      </portal>
    </div>
    <div v-else>
      <forbidden />
    </div>
  </div>
</template>
<script>
import LibrarySidebar from './components/LibrarySidebar.vue'
import LibraryArea from './components/LibraryArea.vue'

export default {
  name: 'MediaLibrary',
  props: [],
  components: {
    LibrarySidebar,
    LibraryArea,
  },
  data() {
    return {
      currentFolder: null,
    }
  },
  created() {},
  methods: {
    onFolderSelected(folder) {
      this.currentFolder = folder
      // this.$router.push({ query: { folder: folder.path } })
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/media-library/media-library.scss';
@import '@/assets/scss/media-library/media-library-list.scss';
@import '@/assets/scss/media-library/upload.scss';
@import '@/assets/scss/media-library/media-library-media-info.scss';

.media-library-edit-area {
  background-color: #fff !important;
}
</style>
